var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c(
          "search-panel",
          {
            attrs: { flex: "", "show-btn": "" },
            on: { getList: _vm.onClickSearch },
          },
          [
            _c(
              "div",
              [
                _c("p", [_vm._v("活动名称")]),
                _c("el-input", {
                  staticStyle: { width: "240px" },
                  attrs: {
                    size: "mini",
                    placeholder: "请输入活动名称",
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchName,
                    callback: function ($$v) {
                      _vm.searchName = $$v
                    },
                    expression: "searchName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("p", [_vm._v("活动状态")]),
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择活动状态",
                      size: "mini",
                      clearable: "",
                    },
                    on: { change: _vm.onClickSearch },
                    model: {
                      value: _vm.searchState,
                      callback: function ($$v) {
                        _vm.searchState = $$v
                      },
                      expression: "searchState",
                    },
                  },
                  _vm._l(_vm.searchStateList, function (item, i) {
                    return _c("el-option", {
                      key: i,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { slot: "button-right", size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.operation("add")
                  },
                },
                slot: "button-right",
              },
              [_vm._v(" 创建活动 ")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { data: _vm.list },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "returnCouponName", label: "活动名称" },
            }),
            _c("el-table-column", {
              attrs: { label: "指定商品", "min-width": "200px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.goodsList && Array.isArray(row.goodsList)
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.goodsList
                                    .map((item) => item.goodsName)
                                    .join(",")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "returnCouponRelationName", label: "返券" },
            }),
            _c("el-table-column", {
              attrs: { label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          style: {
                            color:
                              row.startState === 1
                                ? "green"
                                : row.startState === 2
                                ? "red"
                                : "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.searchStateList.find(
                                  (item) => item.value === row.startState
                                )
                                  ? _vm.searchStateList.find(
                                      (item) => item.value === row.startState
                                    ).label
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "上下架状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        on: {
                          change: (e) => {
                            _vm.changeSwitch(e, scope.row)
                          },
                        },
                        model: {
                          value: scope.row.state,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "state", $$v)
                          },
                          expression: "scope.row.state",
                        },
                      }),
                      _vm._v("   "),
                      _c("span", [
                        _vm._v(_vm._s(scope.row.state == 1 ? "上架" : "下架")),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "活动时间", width: "320px" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.setTime(row.startTime)) +
                          " - " +
                          _vm._s(_vm.setTime(row.endTime)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "150px", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            underline: false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.operation("show", row.returnCouponId)
                            },
                          },
                        },
                        [_vm._v(" 查看 ")]
                      ),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            underline: false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.operation("edit", row.returnCouponId)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.currentPage,
            "page-size": _vm.pageSize,
            "page-sizes": [10, 20, 30, 50],
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "current-change": _vm.handleCurrentChange,
            "size-change": _vm.handleSizeChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }