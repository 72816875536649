<template>
  <div class="app-container">
    <div class="outer">
      <search-panel
        flex
        show-btn
        @getList="onClickSearch"
      >
        <div>
          <p>活动名称</p>
          <el-input
            v-model="searchName"
            size="mini"
            style="width: 240px"
            placeholder="请输入活动名称"
            clearable
          />
        </div>
        <div>
          <p>活动状态</p>
          <el-select
            v-model="searchState"
            placeholder="请选择活动状态"
            size="mini"
            clearable
            @change="onClickSearch"
          >
            <el-option
              v-for="(item, i) in searchStateList"
              :key="i"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          slot="button-right"
          size="mini"
          type="primary"
          @click="operation('add')"
        >
          创建活动
        </el-button>
      </search-panel>

      <el-table
        v-loading="loading"
        :data="list"
      >
        <el-table-column
          prop="returnCouponName"
          label="活动名称"
        />

        <el-table-column
          label="指定商品"
          min-width="200px"
        >
          <template slot-scope="{row}">
            <div v-if="row.goodsList && Array.isArray(row.goodsList)">
              {{ row.goodsList.map( item => item.goodsName).join(',') }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="returnCouponRelationName"
          label="返券"
        />

        <el-table-column label="状态">
          <template
            slot-scope="{row}"
          >
            <div
              :style="{'color': row.startState === 1
                ? 'green'
                : (row.startState === 2 ? 'red': '')}"
            >
              {{ searchStateList.find( item => item.value === row.startState)
                ? searchStateList.find( item => item.value === row.startState).label
                : '' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="上下架状态"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="
                (e) => {
                  changeSwitch(e, scope.row);
                }
              "
            />
            &nbsp;
            <span>{{ scope.row.state == 1 ? "上架" : "下架" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="活动时间"
          width="320px"
        >
          <template slot-scope="{row}">
            {{ setTime(row.startTime) }} - {{ setTime(row.endTime) }}
          </template>
        </el-table-column>

        <el-table-column
          width="150px"
          label="操作"
        >
          <template slot-scope="{row}">
            <el-link
              type="primary"
              size="mini"
              :underline="false"
              @click="operation('show', row.returnCouponId)"
            >
              查看
            </el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link
              type="primary"
              size="mini"
              :underline="false"
              @click="operation('edit', row.returnCouponId)"
            >
              编辑
            </el-link>
            <!-- <el-divider direction="vertical"></el-divider>
            <el-link type="danger" size="mini" :underline="false">删除</el-link> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: '',
  data() {
    return {
      loading: false,
      searchName: '',
      searchState: null,
      searchStateList: [{
        value: 0,
        label: '未开始'
      }, {
        value: 1,
        label: '进行中'
      }, {
        value: 2,
        label: '已结束'
      }],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios.get(this.$api.returnCoupon.page, {
        params: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          returnCouponName: this.searchName,
          startState: this.searchState
        }
      }).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.list = res.data.result
          this.total = Number(res.data.total)
        }
      })
    },
    onClickSearch() {
      this.currentPage = 1
      this.getList()
    },
    // 新增、查看、编辑
    operation(type, id) {
      this.$router.push({
        path: '/nb/marketingTools/orderBackCoupon/detail',
        query: {
          type,
          id
        }
      })
    },
    handleRankStatus(row) {
      const { id, status } = row
      this.$axios.get(this.$api.rank[status === 1 ? 'disableRank' : 'enableRank'], {
        params: {
          rankingId: id
        }

      }).then(res => {
        if (res.code === 0) {
          this.$message.success('操作成功')
          this.getList()
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    onClickDelete(id) {
      this.$confirm('是否确认删除该排行榜？', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.loading = true
        this.$axios.get(this.$api.rank.delete, {
          params: {
            rankingId: id
          }
        }).then(res => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success('删除成功')
            if (this.list.length === 1) {
              this.currentPage = this.currentPage - 1
            }
            this.getList()
          }
        })
      })
    },
    changeSwitch(e, row) {
      let api = this.$api.returnCoupon.enable_state
      let params = {
        enabled: e,
        returnCouponId: row.returnCouponId
      }
      this.$confirm(e === 1 ? '是否确认上架活动?' : '是否确认下架活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get(api, {
              params
            })
            .then((res) => {
              if (res.code === 0) {
                this.$set(row, 'state', e)
                this.$message({
                  message: '操作成功！',
                  type: 'success'
                })
              } else {
                e === 1 ? this.$set(row, 'state', 0) : this.$set(row, 'state', 1)
              }
            })
        })
        .catch(() => {
          e === 1 ? this.$set(row, 'state', 0) : this.$set(row, 'state', 1)
        })
    },
    setTime(num) {
      return dayjs(Number(num)).format('YYYY/MM/DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
